
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { Field } from "vee-validate";
import Insurer from "@/components/reusable/Insurer.vue";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import State from "@/components/reusable/State.vue";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";
import Generators from "@/views/reusable/buy-insurance/house-insurance/generators.json";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "admin-create-migration-dependencies",
  components: { Field, Insurer, State, VehicleMakeAndModel },
  data() {
    return {
      buildingTypes: BuildingTypes,
      generatorLists: Generators,
      clientPublicId: "",
      policyNumber: "",
      motorBusinessType: "",
      houseBusinessType: "",
      underwriterPublicId: "",
      buildingType: "",
      state: "",
      localGovernmentArea: "",
      vehicleMake: "",
      vehicleModel: "",
      yearManufactured: "",
      vehicleColor: "",
      bodyType: "",
      vehiclePackage: "",
      housingPackage: "",
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes,
      generator: "",
    };
  },
  setup() {
    const underwriters = ref([]);
    const clients = ref([
      {
        publicId: "",
        fullName: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
      },
    ]);
    const covers = ref([{ publicId: "", name: "", brokerReference: "" }]);
    const housingCovers = ref([
      { publicId: "", name: "", brokerReference: "" },
    ]);

    const getUnderwriters = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_UNDERWRITERS_LIST_ROUTE)
          .then(({ data }) => {
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getLegacyClients = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_CLIENT)
          .then(({ data }) => {
            clients.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getMotorCovers = (businessType: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.INSURANCE_PACKAGES_ROUTE}/${businessType}`)
          .then(({ data }) => {
            covers.value = data.data;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getHousingCovers = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.INSURANCE_PACKAGES_ROUTE}/HOU`)
          .then(({ data }) => {
            housingCovers.value = data.data;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Dependencies", ["Policy Migration"]);
      getUnderwriters();
      getLegacyClients();
      getHousingCovers();
    });

    return { getMotorCovers, underwriters, clients, covers, housingCovers };
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Legacy Policy Spreadsheet Dependencies | " +
      process.env.VUE_APP_TITLE;
  },
  mounted() {
    const $clientList = $("#client-list");
    $clientList.select2({
      placeholder: "Select a client...",
    });
    $clientList.on("change", this.getClientName);
  },
  methods: {
    getUnderwriterId(underwriterId) {
      this.underwriterPublicId = underwriterId;
    },
    getClientName(event) {
      this.clientPublicId = event.target.value;
    },
  },
});
